import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import { Modal, Media16By9, MediaVideo } from "Components";
import ReactHtmlParser from "react-html-parser";
import { AnalyticsContext } from "context";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, EffectCards } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/effect-cards/effect-cards.min.css";
import img1 from "./img/scotty-mccreery.png";
import img2 from "./img/corey-kent.png";
import img3 from "./img/greylan-james.png";
import img4 from "./img/hailey-whitters.png";
import img5 from "./img/kalie-shorr.png";
import img6 from "./img/morgan-wade.png";
import img1HeroPlay from "./img/scotty-mccreery-play.png";
import listenFB from "./img/Listen-FB.png";
import listenInst from "./img/Listen-Inst.png";
import listenTT from "./img/Listen-TT.png";
import listenFBMobile from "./img/Listen-FB-mobile.png";
import listenInstMobile from "./img/Listen-Inst-mobile.png";
import listenTTMobile from "./img/Listen-TT-mobile.png";

import "./styles.css";
import { useMediaQuery } from "hooks";

export const Slider = (props) => {
  const isMobile = useMediaQuery();
  const [open, setOpen] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [video, setVideo] = useState(null);
  const [artist, setArtist] = useState([]);
  const { logClick } = useContext(AnalyticsContext);
  const setModalVisible = (bool) => {
    setVideo(
      "https://www.youtube.com/embed/LI7hqVg3B2s?autoplay=0&cc_load_policy=1"
    );
    setOpenVideo(bool);
  };

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  const artists = [
    {
      name: "SCOTTY McCREERY",
      origin: "GARNER, NC",
      bio:
        "Scotty McCreery entered the scene in 2011 with his debut album, <span>Clear As Day</span>. He made history as both the first country artist and the youngest male artist of any musical format to debut his first studio album at No.1 on the all-genre <span>Billboard</span> Top 200. He’s won numerous awards and achieved five consecutive No.1 hits, including “Five More Minutes”, “This is It,” and “Damn Strait.” His current album is <span>Same Truck: The Deluxe Album</span>.",
      imageHero: "./img/scotty-mccreery-hero.png",
      imageListen: "./img/scotty-mccreery-listen.png",
      imageListenMobile: "./img/scotty-mccreery-mobile.png",
      listen:
        "https://open.spotify.com/track/0hsUL5MAbpNLKdHblECMU1?si=a55676bd8775444b",
      fb: "https://www.facebook.com/scottymccreery/",
      inst: "https://www.instagram.com/scottymccreery/",
      tiktok: "https://www.tiktok.com/@scottymccreery",
    },
    {
      name: "COREY KENT",
      origin: "TULSA, OK",
      bio:
        "Known for his small-town charm, Corey Kent started his journey at a young age when he took the stage at 11 years old as the lead singer of a touring Western swing band. By the time he was 17, he had hit early milestones opening for acts like Roy Clark and the Oak Ridge Boys while booking gigs around Bixby and eventually making it to Nashville.",
      imageHero: "./img/corey-kent-hero.png",
      imageListen: "./img/corey-kent-listen.png",
      imageListenMobile: "./img/corey-kent-mobile.png",
      listen:
        "https://open.spotify.com/track/0TdzyXMNPMDkDAFB2O3Cwl?si=5f1cce560e5d4ac2",
      fb: "https://www.facebook.com/CoreyKentOfficial/",
      inst: "https://www.instagram.com/coreykent/",
      tiktok: "https://www.tiktok.com/@coreykent",
    },
    {
      name: "GREYLAN JAMES",
      origin: "KNOXVILLE, TN",
      bio:
        "Greylan James has written songs for Kenny Chesney, Blake Shelton, Chris Young, and Chis Janson. He wrote Chesney’s hit song, “Happy Does,” which hit number 2 on the Billboard Country Airplay chart. His two singles, “Anything Cold” and “You and Jesus,” have over 410 thousand streams on Spotify alone.",
      imageHero: "./img/greylan-james-hero.png",
      imageListen: "./img/greylan-james-listen.png",
      imageListenMobile: "./img/greylan-james-mobile.png",
      listen: "https://open.spotify.com/track/6At2ZQA676m2DbbstWSUMk",
      fb: "https://www.facebook.com/greylanjamesmusic/",
      inst: "https://www.instagram.com/greylanjames/",
      tiktok: "https://www.tiktok.com/@greylanjames",
    },
    {
      name: "HAILEY WHITTERS",
      origin: "SWISHER, IA",
      bio:
        "Hailey Whitters has written songs for Little Big Town and Alan Jackson. Her 2022 album, Raised, was her first album to hit the Billboard charts. When it debuted, it reached number 18 on the Heatseekers Albums chart, stayed there for six weeks, and peaked at number 9. She also has a Grammy nomination for Song of the Year for “A Beautiful Noise.”",
      imageHero: "./img/hailey-whitters-hero.png",
      imageListen: "./img/hailey-whitters-listen.png",
      imageListenMobile: "./img/hailey-whitters-mobile.png",
      listen:
        "https://open.spotify.com/track/3zAOcNGOFq5Q1OsN0tcxA0?si=bd241d1e3dcf4783",
      fb: "https://www.facebook.com/HAILEYWHITTERSMUSIC/",
      inst: "https://www.instagram.com/haileywhitters/",
      tiktok: "https://www.tiktok.com/@haileywhitters",
    },
    {
      name: "KALIE SHORR",
      origin: "PORTLAND, ME",
      bio:
        "Kalie Shorr is a country singer and songwriter. The New York Times ranked Kalie’s album, Open Book, at number 7 alongside artists like Ariana Grande and Taylor Swift. Since her debut, she has been described as a “Top 10 Country Artist to Watch in 2018” by the Huffington Post and “CMT Next Women of Country” by Teen Vogue.",
      imageHero: "./img/kalie-shorr-hero.png",
      imageListen: "./img/kalie-shorr-listen.png",
      imageListenMobile: "./img/kalie-shorr-mobile.png",
      listen:
        "https://open.spotify.com/track/2IRZm2pbQYoA5Q2gbwE6nU?si=a0def4cfb44c4a45",
      fb: "https://www.facebook.com/kalieshorr",
      inst: "https://www.instagram.com/kalieshorr/",
      tiktok: "https://www.tiktok.com/@kalieshorr",
    },
    {
      name: "MORGAN WADE",
      origin: "FLOYD, VA",
      bio:
        "Morgan Wade’s first album, Reckless, has been described as bold and startling. In 2021, Reckless was cited on Rolling Stone Country’s 25 Best Country and Americana Albums list. It also charted at number 13 on Rolling Stone’s all-genre Top 50 Albums list and number 4 on Best Country Albums lists from Taste of Country and Stereogum.",
      imageHero: "./img/morgan-wade-hero.png",
      imageListen: "./img/morgan-wade-listen.png",
      imageListenMobile: "./img/morgan-wade-mobile.png",
      listen:
        "https://open.spotify.com/track/2vubFQgAJO5SeuVa7ytIHc?si=e40a2faccf5d4a94",
      fb: "https://www.facebook.com/morganwademusic/",
      inst: "https://www.instagram.com/morganwademusic/",
      tiktok: "https://www.tiktok.com/@morganwademusic",
    },
  ];

  const renderArtist = (i) => {
    setArtist([]);
    let array = [];
    array.push(artists[i]);
    setArtist(array);
  };
  return (
    <>
      <Wrap mobile={props.mobile}>
        <Swiper
          effect={"cards"}
          grabCursor={true}
          pagination={{ clickable: true }}
          spaceBetween={30}
          slidesPerView={"auto"}
          centeredSlides={true}
          //   loop={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, EffectCards, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img
              src={img1}
              alt="artist scotty mccreery"
              onClick={() => {
                setOpen(true);
                renderArtist(0);
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={img2}
              alt="artist "
              onClick={() => {
                setOpen(true);
                renderArtist(1);
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={img3}
              alt="artist "
              onClick={() => {
                setOpen(true);
                renderArtist(2);
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={img4}
              alt="artist "
              onClick={() => {
                setOpen(true);
                renderArtist(3);
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={img5}
              alt="artist "
              onClick={() => {
                setOpen(true);
                renderArtist(4);
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={img6}
              alt="artist "
              onClick={() => {
                setOpen(true);
                renderArtist(5);
              }}
            />
          </SwiperSlide>
        </Swiper>
      </Wrap>
      <Modal
        open={open}
        size={"large"}
        isMobile={isMobile}
        setOpen={setOpen}
        hero={true}
      >
        {artist &&
          artist?.map((artist) => {
            return (
              <ModalInnerWrap isMobile={isMobile}>
                <InfoBox isMobile={isMobile} s>
                  <h1>{artist?.name} </h1>
                  <h4>{artist?.origin}</h4>
                  <p>{ReactHtmlParser(artist?.bio)}</p>
                  {artist?.name === "SCOTTY McCREERY" && (
                    <PlayButton
                      src={img1HeroPlay}
                      onClick={() => {
                        setModalVisible(true);
                        linkClickHandler(
                          "Locally-Grown story",
                          "Hero video",
                          "internal"
                        );
                      }}
                      alt="play artist song button"
                    />
                  )}
                  <SocialContainer isMobile={isMobile}>
                    <img
                      onClick={() => {
                        window.open(artist?.listen);
                        linkClickHandler(
                          artist?.listen,
                          `${artist?.name} song`,
                          "external"
                        );
                      }}
                      src={
                        isMobile
                          ? artist?.imageListenMobile
                          : artist?.imageListen
                      }
                      alt="play artist's music on spotify"
                    />
                    <img
                      onClick={() => {
                        window.open(artist?.tiktok);
                        linkClickHandler(
                          artist?.tiktok,
                          `${artist?.name} tiktok`,
                          "external"
                        );
                      }}
                      src={isMobile ? listenTTMobile : listenTT}
                      alt="follow artist's music on tiktok"
                    />
                    <img
                      onClick={() => {
                        window.open(artist?.inst);
                        linkClickHandler(
                          artist?.inst,
                          `${artist?.name} instagram`,
                          "external"
                        );
                      }}
                      src={isMobile ? listenInstMobile : listenInst}
                      alt="follow artist's music on instagram"
                    />
                    <img
                      onClick={() => {
                        window.open(artist?.fb);
                        linkClickHandler(
                          artist?.fb,
                          `${artist?.name} facebook`,
                          "external"
                        );
                      }}
                      src={isMobile ? listenFBMobile : listenFB}
                      alt="follow artist's music on facebook"
                    />
                  </SocialContainer>
                </InfoBox>
                <HeroBox src={artist?.imageHero} isMobile={isMobile}></HeroBox>
              </ModalInnerWrap>
            );
          })}
      </Modal>
      <Modal open={openVideo} size={"full"} setOpen={setModalVisible}>
        <Media16By9>
          <MediaVideo
            src={video}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </Media16By9>
      </Modal>
    </>
  );
};

const Wrap = styled.div`
  width: 50%;
  min-width: 400px;
  height: auto;
  position: absolute;
  left: 10%;
  top: 15%;
  z-index: 10;

  ${(props) =>
    props.mobile &&
    css`
      position: relative;
      width: 70%;
      min-width: 250px;
      margin: 10px auto 40px auto;
      left: 0;
      top: 0;
    `}
`;
const ModalInnerWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;

  ${(props) =>
    props.isMobile &&
    css`
      flex-direction: column-reverse;
    `}
`;
const InfoBox = styled.div`
  padding: 20px 50px 45px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: center;
  font-family: "BrandonReg";
  h1,
  h4 {
    font-family: "BrandonBld";
    font-weight: 900;
    margin: 10px 0;
  }
  p {
    font-size: 16px;
    span {
      font-family: "BrandonRegIt";
    }
  }
  ${(props) =>
    props.isMobile &&
    css`
      padding: 10px;

      & > p {
        font-size: 14px;
      }
    `}
`;
const HeroBox = styled.div`
  border-radius: 0 13px 13px 0;
  flex: 1;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  ${(props) =>
    props.isMobile &&
    css`
      border-radius: 9px;
      aspect-ratio: 1/1.2;
      background-position: top;
    `}
`;
const PlayButton = styled.img`
  width: 60%;
`;
const SocialContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 35px;
  flex-direction: row;
  justify-content: space-between;
  max-height: 100px;
  justify-self: flex-end;
  img {
    width: 80px;
  }
  ${(props) =>
    props.isMobile &&
    css`
      margin-top: 20px;
      flex-direction: column;
      max-height: unset;

      img {
        width: 100%;
        margin-bottom: 15px;
      }
    `}
`;
