import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
} from "Components";
import { RegistrationForm } from "Components/Form";
import { Slider } from "Components/Slider";
import { useMediaQuery } from "hooks";

export const RegistrationLayout = React.memo(
  ({
    sweeps,
    postSweeps,
    ineligible,
    formStep = 0,
    registered,
    postCampaign,
    setIneligible,
  }) => {
    const { event } = useContext(EventContext);
    const { logVideoAction, logClick } = useContext(AnalyticsContext);

    const [open, setOpen] = useState(false);
    const [video, setVideo] = useState(null);

    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event.meta.storageDirectory;

    const isMobile = useMediaQuery();
    // logo
    const { brand: brandLogo } = event.logos;
    let brandLogoImg = `${api}/${storageDirectory}/${brandLogo?.image}`;
    let brandHeadlineImg = `${api}/${storageDirectory}/${brandLogo?.brandHeadline}`;
    let regHeadlineImg = `${api}/${storageDirectory}/${brandLogo?.regHeadline}`;
    let postHeadlineImg = `${api}/${storageDirectory}/${brandLogo?.postHeadline}`;

    if (registered) formStep = event.register.sweeps.length - 1;
    // registration content
    const {
      heading,
      subHeading,
      body,
      formHeading,
      formLegal,
      submit,
    } = event.register.sweeps[formStep];

    // music video background
    const {
      background: musicVideoBg,
      label: musicVideoLabel,
      video: musicVideoLink,
    } = event.callToActions.musicVideo;
    let videoBgDesktop = `${api}/${storageDirectory}/${musicVideoBg.desktop}?alt=media`;
    let videoBgMobile = `${api}/${storageDirectory}/${musicVideoBg.mobile}?alt=media`;

    // brand cta
    const {
      btnLink: brandCTALink,
      btnText: brandCTAText,
      heading: brandCTAHeading,
    } = event.callToActions.brand;

    // brand cta background
    const {
      desktop: brandBgD,
      mobile: brandBgM,
    } = event.callToActions.brand.background;
    let brandBgDesktop = `${api}/${storageDirectory}/${brandBgD}?alt=media`;
    let brandBgMobile = `${api}/${storageDirectory}/${brandBgM}?alt=media`;

    // brand product background
    const {
      desktop: brandProductDesktop,
      mobile: brandProductMobile,
    } = event.callToActions.brand.product;
    let productDesktop = `${api}/${storageDirectory}/${brandProductDesktop}?alt=media`;
    let productMobile = `${api}/${storageDirectory}/${brandProductMobile}?alt=media`;

    // post-campaign content
    const {
      heading: postCampaignHeading,
      body: postCampaignBody,
    } = event.postCampaign;

    // brand cta (post-campaign)
    const {
      btnLink: brandSecondaryCTALink,
      btnLinkPostCampaign: PostCampaignCTALink,
      btnText: brandSecondaryCTAText,
      heading: brandSecondaryCTAHeading,
    } = event.callToActions.brandSecondary;

    const linkClickHandler = (url, label, category) => {
      logClick({ label, url }, category);
    };

    const setModalVisible = (bool, link) => {
      setVideo(link);
      setOpen(bool);
    };

    return (
      <Layout
        registration={postCampaign ? false : true}
        flexGrow
        showFooter
        postCampaign={postCampaign}
      >
        <Container postCampaign={postCampaign}>
          <Content>
            <LogoBox>
              <Link
                href={brandLogo.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  linkClickHandler(brandLogo.link, "USC Homepage", "header")
                }
              >
                {brandLogoImg && <Logo src={brandLogoImg} />}
              </Link>
            </LogoBox>

            <ContainerPadding
              isRegistered={registered || postCampaign}
              unRegistered={!registered}
            >
              {isMobile && <Slider mobile />}

              <HeadlineImg
                src={
                  registered || postCampaign ? regHeadlineImg : brandHeadlineImg
                }
                headline={true}
                alt="locally grown, locally live. VIP fan experience"
              />

              {!postCampaign ? (
                <>
                  {/* ===== CAMPAIGN INTRO ===== */}
                  {heading && <Heading>{ReactHtmlParser(heading)}</Heading>}
                  {subHeading && (
                    <SubHeading>{ReactHtmlParser(subHeading)}</SubHeading>
                  )}
                  {body && <Body>{ReactHtmlParser(body)}</Body>}

                  {/* ===== REGISTRATION FORM ===== */}
                  {registered ? (
                    <ButtonPrimary
                      as="a"
                      href={brandSecondaryCTALink}
                      onClick={() =>
                        linkClickHandler(
                          brandSecondaryCTALink,
                          "Learn more",
                          "external"
                        )
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {brandSecondaryCTAText}
                    </ButtonPrimary>
                  ) : (
                    <RegistrationForm
                      formPart={formStep}
                      formHeading={formHeading}
                      formLegal={formLegal}
                      submitButton={submit}
                      setIneligible={setIneligible}
                    />
                  )}
                </>
              ) : (
                <>
                  {/* ===== POST-CAMPAIGN HEADINGS ===== */}
                  {postCampaignHeading && (
                    <HeadingPostCampaign>
                      {ReactHtmlParser(postCampaignHeading)}
                    </HeadingPostCampaign>
                  )}
                  <HeadlineImg
                    src={postHeadlineImg}
                    headline={true}
                    alt="locally grown, locally live. VIP fan experience"
                  />
                  {postCampaignBody && (
                    <BodyPostCampaign>
                      {ReactHtmlParser(postCampaignBody)}
                    </BodyPostCampaign>
                  )}

                  {/* ===== POST-CAMPAIGN CTA ===== */}
                  <BrandSecondaryCTA>
                    <HeadingPostCampaign>
                      {ReactHtmlParser(brandSecondaryCTAHeading)}
                    </HeadingPostCampaign>
                    <ButtonPrimary
                      as="a"
                      href={PostCampaignCTALink}
                      onClick={() =>
                        linkClickHandler(
                          PostCampaignCTALink,
                          "Learn More",
                          "external"
                        )
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {brandSecondaryCTAText}
                    </ButtonPrimary>
                  </BrandSecondaryCTA>
                </>
              )}

              {(formStep !== 0 || ineligible || postCampaign) && (
                <PoweredBy postCampaign={postCampaign}>
                  <SubBody>{ReactHtmlParser(event.register.poweredBy)}</SubBody>
                </PoweredBy>
              )}
            </ContainerPadding>
          </Content>

          <ArtistContent>
            {!isMobile && <Slider />}
            <Pattern src={brandBgDesktop} alt="brand colors pattern" />
          </ArtistContent>
          {isMobile && (
            <PatternMobile
              isRegistered={registered || postCampaign}
              src={brandBgMobile}
              alt="usc colors pattern"
            />
          )}
        </Container>
        <Modal open={open} setOpen={setModalVisible}>
          <Media16By9>
            <MediaVideo
              src={video}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Media16By9>
        </Modal>
      </Layout>
    );
  }
);

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* padding: 0 0 25px 0; */
  /* gap: 70px; */
  background-color: #fff;
  position: relative;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
    padding: 0px 0;
  }
`;

const Content = styled.div`
  @media (min-width: 950px) {
    padding-left: 100px;
    position: relative;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 45%;
    max-width: 530px;
  }
`;

const ContainerPadding = styled.div`
  padding: 0 0px 50px;
  @media (max-width: 950px) {
    padding: 0 30px 30px;
    ${(props) =>
      !props.isRegistered &&
      // !props.unRegistered &&
      css`
        padding: 0 20px;
      `}
  }
`;

const LogoBox = styled.div`
  padding: 20px 0 15px;
  display: flex;
  justify-content: center;
`;

const Link = styled.a`
  width: 100%;

  @media (max-width: 950px) {
    max-width: 300px;
    margin: 0 auto;
  }
`;

const Logo = styled.img`
  width: 70%;
  display: flex;

  @media (max-width: 950px) {
    width: 80%;
    margin: 0 auto;
  }
`;
const HeadlineImg = styled.img`
  width: 90%;
  display: flex;
  padding: 20px 0 10px;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

const MainHeading = styled.h1`
  padding-bottom: 24px;

  & > span {
    display: block;
    font-size: 20px;
    padding-top: 10px;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 2rem;

    & > span {
      font-size: 1.2rem;
    }
  }
`;

const Heading = styled.h2`
  font-size: 30px;
`;
const SubHeading = styled.h3`
  font-weight: 200;
`;
const Body = styled.p`
  font-size: 20px;
  font-family: "BrandonReg";

  span.headline {
    font-size: 30px;
    color: #0731a1;
    display: inline-block;
    margin: 25px 0 10px;
    font-family: "BrandonMed";
  }
  span.super {
    font-size: 12px;
    display: inline-block;
    margin: -10px 0 0 0;
    position: relative;
    top: -5px;
  }

  @media (max-width: 950px) {
    font-size: 0.9rem;
  }
`;
const HeadingPostCampaign = styled(Heading)`
  // padding-right: 40px;
`;
const BodyPostCampaign = styled(Body)`
  @media (max-width: 950px) {
    font-size: 0.9rem;
  }
`;

const SubBody = styled.p`
  font-size: 0.65rem;

  @media (max-width: 950px) {
    font-size: 0.9rem;
  }
`;

const topBorder = `
  border-top: 1px solid #707070;
  padding-top: 24px;
  margin-top: 24px;

`;

const PoweredBy = styled.div`
  ${(props) => props.postCampaign && "border-top: 0px solid #707070;"}
`;

const BrandSecondaryCTA = styled.div`
  font-size: 0.8rem;

  a {
    @media (max-width: 950px) {
      align-self: center;
      display: block;
      width: 50%;
      margin: 0 auto;
      padding: 10px 0;
      font-size: 0.9rem;
      margin-top: 40px;
    }
  }
`;

const ArtistContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  background-color: #fff;
`;

const ArtistContentTop = styled.div`
  display: flex;
`;

const BrandImage = styled.div`
  flex: 1;
  background-image: url(${(props) => props.mobile});
  background-position: center;
  background-size: cover;
  padding-top: 56.25%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.desktop});
    background-position: center;
  }
`;

const HeroVideoMobile = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const HeroVideoDesktop = styled.div`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: flex;
    width: 66%;
  }
`;

const MusicVideo = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: 1 1 0%;
    padding: 0;
  }
`;

const MobileImage = styled.img`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const DesktopImage = styled.img`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: block;
    width: 100%;
  }
`;

const BrandCTA = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 16/9;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    position: absolute;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    & > img {
      width: 100%;
    }
  }
`;

const BrandCTAHeading = styled.h3`
  color: #ffffff;
  padding: 0 40px;
  z-index: 1;
  font-size: 1.7rem;
`;

const Label = styled.div`
  background-color: rgba(0, 9, 91, 0.9);
  color: #ffffff;
  padding: 10px 35px;
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  text-decoration: none;
  font-family: "BrandonReg";
  font-size: 13px;
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 13px;
  }
`;
const Pattern = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1;
`;
const PatternMobile = styled.img`
  width: 100%;
  margin-top: -50px;
  /* position: absolute;
  bottom: 8%;
  left: 0;
  z-index: 1;
  ${(props) =>
    !props.isRegistered &&
    css`
      bottom: 7%;
    `} */
`;
const StyledSlider = styled(Slider)`
  /* position: absolute;
  top: 50%;
  right: 50%;
  z-index: 10; */
`;
