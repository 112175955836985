import { useContext } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { TimeContext, EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";
import { useMediaQuery } from "hooks";

export const Footer = ({
  showFooter,
  registration,
  registered,
  className,
  voting,
  postCampaign,
  preCampaign,
}) => {
  const { time } = useContext(TimeContext);
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;
  // brand cta background
  const {
    desktop: brandBgD,
    mobile: brandBgM,
  } = event.callToActions.brand.background;
  let brandBgDesktop = `${api}/${storageDirectory}/${brandBgD}?alt=media`;
  let brandBgMobile = `${api}/${storageDirectory}/${brandBgM}?alt=media`;

  const isMobile = useMediaQuery();
  const {
    // brand: brandLogo,
    // event: eventLogo,
    secondary: secondaryLogo,
  } = event.logos;

  let secondaryLogoImg = `${api}/${storageDirectory}/${secondaryLogo?.image}?alt=media`;

  const footerMenu = false;
  const showLogo = true;

  const {
    maxPolicy,
    uscPolicy,
    brandDisclaimer,
    sweepsDisclaimer,
    grandPrizeDisclaimer,
  } = event.footer;

  const logoClickHandler = (link) => {
    logClick({
      label: "Footer Logo",
      url: link,
    });
  };

  const footerMenuClickHandler = (link) => {
    logClick({
      label: "Footer Menu Link",
      url: link,
    });
  };

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  return (
    <>
      {showFooter && (
        <FooterWrapper as="footer" className={className}>
          <FooterContainer isMobile={isMobile}>
            {preCampaign
              ? maxPolicy &&
                uscPolicy && (
                  <>
                    <MaxPolicy
                      onClick={() =>
                        linkClickHandler(
                          "https://www.uscellular.com/privacy",
                          "UScellular Privacy Policy",
                          "footer"
                        )
                      }
                    >
                      {ReactHtmlParser(uscPolicy)}
                    </MaxPolicy>
                    <MaxPolicy
                      onClick={() =>
                        linkClickHandler(
                          "https://www.max.live/privacy-policy",
                          "MAX privacy policy",
                          "footer"
                        )
                      }
                    >
                      {ReactHtmlParser(maxPolicy)}
                    </MaxPolicy>
                    <MaxPolicy>
                      <CookieButton
                        onClick={() => {
                          if (window.Cookiebot) {
                            window.Cookiebot.renew();
                          }
                        }}
                      >
                        Click here to set Cookie Preferences.
                      </CookieButton>
                    </MaxPolicy>
                  </>
                )
              : brandDisclaimer && (
                  <BrandDisclaimer
                    onClick={() =>
                      linkClickHandler(
                        "http://bit.ly/3Er5bTW",
                        "Footer Rules/privacy policy",
                        "footer"
                      )
                    }
                  >
                    {ReactHtmlParser(brandDisclaimer)} &nbsp;
                    <CookieButton
                      onClick={() => {
                        if (window.Cookiebot) {
                          window.Cookiebot.renew();
                        }
                      }}
                    >
                      Click here to set Cookie Preferences.
                    </CookieButton>
                  </BrandDisclaimer>
                )}
          </FooterContainer>
        </FooterWrapper>
      )}
    </>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  font-size: 13px;
  z-index: 1;
  position: relative;

  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
    max-width: 1700px;
    margin: -5px auto 0 auto;
    position: relative;
  }
`;

const FooterContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  padding: 35px 150px 35px 95px;
  background-color: #0731a1;
  color: #fff;
  z-index: 10;

  gap: 30px;

  a {
    color: #fff;
  }
  ${(props) =>
    props.isMobile &&
    css`
      flex-direction: column;
      gap: 0px;
      padding: 30px;
      align-items: center;
    `}
`;
const CookieButton = styled.button`
  border: none;
  background-color: transparent;
  color: #ffffff;
  display: inline;
  font-size: 13px;
  font-weight: 300;
  text-decoration: underline;
  font-family: "BrandonReg";
  cursor: pointer;
  padding: 0;
`;
const Link = styled.a`
  margin: 15px;
  align-self: center;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin: 0 0 0 50px;
    align-self: start;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 234px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: unset;
  }
`;

const SweepsDisclaimer = styled.div`
  margin-top: 0;
  font-size: 0.5rem;
`;

const MaxPolicy = styled.p`
  margin-top: 0;
`;

const BrandDisclaimer = styled.div`
  margin-bottom: 20px;
  /* padding: 10px 60px; */
`;

const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  li {
    padding: 3px 8px;
  }

  a,
  a:visited {
    color: #fff;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }
`;
const PatternMobile = styled.img`
  position: absolute;
  top: -200px;
  left: 0;
  width: 100%;
  z-index: 0;
`;
